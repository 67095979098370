// @ts-nocheck
import console from "../../log";
export default function XMLHttpRequestOverride(initialize) {
    console.log("Registering XML override");
    const open = window.XMLHttpRequest.prototype.open;
    function openReplacement() {
        this.addEventListener("load", function () {
            if ([
                "/cart/add.js",
                "/cart/update.js",
                "/cart/change.js",
                "/cart/clear.js",
                "/cart/add",
                "/cart/update",
                "/cart/clear",
                "/cart/change",
            ].includes(this._url)) {
                initialize();
                // calculateShipping(this.response);
            }
        });
        return open.apply(this, arguments);
    }
    window.XMLHttpRequest.prototype.open = openReplacement;
}
