// @ts-nocheck
import console from "../../log";
export default function fetchOverride(initialize) {
    (function (ns, fetch) {
        if (typeof fetch !== "function")
            return;
        ns.fetch = function () {
            console.debug("Real ID :: Shopify :: applying fetch override");
            const response = fetch.apply(this, arguments);
            response.then((res) => {
                if ([
                    `${window.location.origin}/cart/add.js`,
                    `${window.location.origin}/cart/update.js`,
                    `${window.location.origin}/cart/change.js`,
                    `${window.location.origin}/cart/clear.js`,
                    `${window.location.origin}/cart/add`,
                    `${window.location.origin}/cart/update`,
                    `${window.location.origin}/cart/change`,
                    `${window.location.origin}/cart/clear`,
                ].includes(res.url)) {
                    initialize();
                }
            });
            return response;
        };
    })(window, window.fetch);
}
