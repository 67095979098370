import { getShopifyCustomerId, getShopifyShopName } from "../helpers";
import { getCheckIdFromLocalStorage } from "./precheckout";
import axios from "../utilities/axios";
import console from "../log";
/**
 * Associate the ID check's unique ID stored in localStorage with the current order visible on the post-checkout page
 *
 * Debating if this really needs to be a part of real-id-flow or if we can just keep it in a separate script
 * @return void
 */
export async function associateShopifyIdCheckWithOrder() {
    const orderId = getShopifyCustomerId();
    const checkId = getCheckIdFromLocalStorage();
    const shopName = getShopifyShopName();
    if (orderId && checkId && shopName) {
        await axios.post(`${process.env.VITE_REAL_ID_HOST}/api/shops/${shopName}/checks/${checkId}/orders/${orderId}/associate`);
    }
}
export async function associateWcIdCheckWithOrder() {
    const checkId = getCheckIdFromLocalStorage();
    const orderId = window.realIdOrder?.id;
    const customerId = window.realIdOrder?.customer?.id;
    console.debug(`Real ID :: post-checkout order ID association :: associating ${checkId} with ${orderId}`);
    if (orderId && checkId) {
        await axios.post(`${process.env.VITE_REAL_ID_HOST}/api/checks/${checkId}/associate`, {
            wcOrderId: orderId,
            wcCustomerId: customerId,
        });
    }
}
