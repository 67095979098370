/**
 * Thin wrapper around console logging to prevent leaking of messages to production sites
 */
export default {
    log: (...args) => {
        if (process.env.NODE_ENV === "development" ||
            window.localStorage.getItem("real-id-debug")) {
            console.log.apply(this, args);
        }
    },
    debug: (...args) => {
        if (process.env.NODE_ENV === "development" ||
            window.localStorage.getItem("real-id-debug")) {
            console.debug.apply(this, args);
        }
    },
    warn: (...args) => {
        if (process.env.NODE_ENV === "development" ||
            window.localStorage.getItem("real-id-debug")) {
            console.warn.apply(this, args);
        }
    },
    error: (...args) => {
        if (process.env.NODE_ENV === "development" ||
            window.localStorage.getItem("real-id-debug")) {
            console.error.apply(this, args);
        }
    },
    table: (...args) => {
        if (process.env.NODE_ENV === "development" ||
            window.localStorage.getItem("real-id-debug")) {
            console.table.apply(this, args);
        }
    },
    dir: (...args) => {
        if (process.env.NODE_ENV === "development" ||
            window.localStorage.getItem("real-id-debug")) {
            console.dir.apply(this, args);
        }
    },
};
