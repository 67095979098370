export default class WPSessionUpdateFailed extends Error {
    status;
    statusText;
    url;
    checkId;
    constructor(status, statusText, url, checkId) {
        super(`S3 Presigned Upload failed! Status: ${status} ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.url = url;
        this.checkId = checkId;
        // Set the prototype explicitly to ensure instanceof works
        Object.setPrototypeOf(this, WPSessionUpdateFailed.prototype);
    }
}
