import axios from 'axios';
import * as Sentry from "@sentry/react";
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    Sentry.captureException(error);
    return Promise.reject(error);
});
export default axios;
