import { MOUNT_POINT_CLASSNAME } from "./definitions";
import axios from "axios";
import { getWcRestApiRoot } from "../helpers";
import WPSessionUpdateFailed from "../exceptions/WPSessionUpdateFailed";
export function checkVerificationStatusByLocalStorage(checkId) {
    return (window.localStorage.getItem(`real-id-check-${checkId}-completed`) === "true");
}
export function setCheckVerificationStatusInLocalStorage(checkId, state = true) {
    return window.localStorage.setItem(`real-id-check-${checkId}-completed`, state.toString());
}
export function setCheckIdInLocalStorage(checkId) {
    window.localStorage.setItem("real-id-check-id", checkId);
}
export function getCheckIdFromLocalStorage() {
    return window.localStorage.getItem("real-id-check-id");
}
export function getCheckIdFromQueryParams() {
    const currentQueryParams = new URLSearchParams(window.location.search);
    return currentQueryParams.get("checkId");
}
export function hideVerifyIdButton() {
    const checkBtnEls = document.getElementsByClassName(MOUNT_POINT_CLASSNAME);
    Array.prototype.forEach.call(checkBtnEls, (el) => {
        el.style.display = "none";
    });
}
export function enableCheckoutButtons(entrySelectors) {
    const nodes = document.querySelectorAll(entrySelectors.join(", "));
    if (!nodes) {
        return null;
    }
    [...nodes].map((el) => {
        el.disabled = false;
    });
}
/**
 * Store the check ID in the WP session (server side)
 * This is necessary for the checkout validation and post-checkout order <> check association
 *
 *
 * @param checkId
 * @param retries
 * @param delay
 */
export function setCheckIdInWcSession(checkId, retries = 5, delay = 1000) {
    const makeRequest = (attempt = 0) => {
        axios
            .post(getWcRestApiRoot() + "real-id/v1/public/session", {
            check_id: checkId,
        }, {
            headers: {
                ...(window?.realIdWpNonce
                    ? { "X-WP-Nonce": window?.realIdWpNonce }
                    : {}),
            },
        })
            .then(() => console.log("wc session updated"))
            .catch((e) => {
            console.error(e);
            if (attempt < retries) {
                setTimeout(() => makeRequest(attempt + 1), delay);
            }
            else {
                console.error("Max retries reached. Could not update wc session.");
            }
            if (axios.isAxiosError(e)) {
                throw new WPSessionUpdateFailed(e.response?.status, e.response?.data, e.request.url, checkId);
            }
        });
    };
    makeRequest();
}
